import React from 'react'
import heroBG from '../../assets/images/bg.jpg'

const Home = () => {
  return (
    <div className='relative h-dvh w-screen'>
        <div className='absolute w-full h-full left-0 top-0 after:bg-black after:bg-opacity-80 after:w-full after:h-full after:top-0 after:left-0 after:z-10 after:absolute'>
            <img src={heroBG} alt="hero-bg" className='w-full h-full object-cover block' />
        </div>
        <h1 className='sm:text-[6vw] text-[8vw] whitespace-nowrap font-black z-20 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white'>Meet, Chat, Date</h1>
    </div>
  ) 
}

export default Home
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

const CommunityGuidelines = () => {
    return (
        <div className='max-w-3xl px-3 py-6 mx-auto text-xl'>
            <div className='max-w-24 mb-4 mx-auto'>
                <Link to='/'>
                    <img src={logo} alt="dipss" />
                </Link>
            </div>
            <h2 className='text-3xl font-bold text-center mb-4'>User Guidelines</h2>
            <p>Thank you for choosing Dipss. We are delighted to have you on the Dipss Platform. Dipss is a place where you can connect with new people. We want you to have a fun and safe experience when you use our Platform. To ensure that, we have a few guidelines for everyone to follow.
            </p>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                Profile
            </div>
            <p>Please write your true and correct details in your profile. Write your real first name and other details. Please use your own photo in your profile. Dipss recommends using the photo verification option so that other users know that your profile is true. When you verify yourself, you will have the option to view only verified profiles. Please do not write provocative or controversial username. Doing so can compromise your safety by attracting the wrong kind of crowd.</p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Account
            </div>
            <p>Shared accounts are not allowed on Dipss Platform. You cannot share your account with your friends or family. Dipss recommends that you be authentic and individual self.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Account Password
            </div>
            <p>
                Please do not share or disclose your password to anyone. Please inform us immediately if you think that your password has been compromised in any way. If you think that your Dipss profile may be hacked, please report to us immediately using the contact details below.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Messaging
            </div>
            <div className='space-y-4'>
                <p>Please do not send the same message to everyone you like, we recommend reading the profiles of other members and messaging them accordingly, after all, not everyone has the same personality!
                </p>
                <p>
                    We want everyone to have a good experience on Dipss. Please refrain from using abusive language when you talk to someone on the Dipss Platform.
                </p>
            </div>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                No Hate Speech
            </div>
            <p>
                We absolutely do not tolerate hate speech. A hate speech may include (without limits) materials that discriminates on the basis of race, ethnicity, religious affiliation, ability, gender identity, age, national origin, sexual orientation, or anything that is directly offensive to any member of a particular community. If we discover that your profile contains such discriminatory materials or that you have discriminated against other users of the Dipss Platform, we will cancel and remove your Dipss account permanently. Please be kind to others and refrain from using exclusionary language on your Dipss profile or otherwise.
            </p>



            <div className='mt-5 font-semibold text-2xl uppercase'>
                FINANCIAL DETAILS
            </div>
            <p>
                If you do not know a person well enough (through the Platform), you should not meet them in real life. If someone asks for your financial details through the in-built chat feature of the Platform, it is most definitely a scam and we suggest you report such user to us immediately. You should never share your financial details with anyone on the Dipss Platform.
            </p>



            <div className='mt-5 font-semibold text-2xl uppercase'>
                Beware of money scams
            </div>
            <p>
                Watch out for money scams. There are just too many con artists and scam artists around the world, and they are everywhere. While we ensure to scan and filter these profiles away, you also need to keep a watch and report for any abnormalities.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Extent of Anonymity
            </div>
            <p>
                Your safety is our priority. We recommend that you do not post your last name, phone number, home or work address, email or social media account details anywhere on your Dipss profile. When you talk to someone, we recommend that you keep all conversations limited to the Dipss Platform. We can only keep you safe on the Platform.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Photos
            </div>
            <p>Please post photos of yourself that show and represent you. Please do not upload photos which:</p>
            <ul className='list-disc pl-8'>
                <li>Don’t show or obscure your face.</li>
                <li>Have watermarks, logos, overlaying text or heavy editing.</li>
                <li>Contain pornography or explicit content.</li>
                <li>Use people or celebrities who aren’t you.</li>
                <li>Show any illegal content, violent or offensive material.</li>
                <li>Feature anyone under the age of 18.</li>
            </ul>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                Meeting IRL
            </div>
            <p>
                Before meeting up with other users of the Dipss Platform in real life, we recommend that you try to know that person as much as possible. You should ask them for recently taken photos (up to 4 weeks old). You should never meet someone if they are trying to pressure you into meeting them. You should try and talk to people on the phone prior to meeting them in real life. If you do decide to meet someone in real life, you should inform your family or friends as to where and who you are meeting up with and also arrange a check-up call or text with them so that you can let them know that you are fine and having fun! Do not set up a meeting in secluded areas and arrange your own transportation to and from the meeting place. We suggest you set up a meeting during the daylight hours. Keep your drinks, food and other belongings within your sight at all times.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                Acceptance
            </div>
            <p>
                By using the Dipss Platform and the Services we provide you through the Platform, you hereby agree to adhere to the terms of our Privacy Policy, Terms and Conditions and this User Guidelines and the Additional Terms we may post from time to time as well as any other documents we post through the Dipss Platform about which you will be notified through the Dipss Platform or through email. If you do not adhere to our terms, we reserve the right to permanently ban you from the Dipss Platform and our Services.
            </p>


            <div className='mt-5 font-semibold'>
                CONTACT US
            </div>
            <div>
                <p>If you have any queries on any aspect of our Privacy Policy, please contact our Data Protection Officer on the details below:
                </p>
                <p>Name : Sanjay Timbadiya </p>
                <p>Email : sanjaypatel121991@gmail.com</p>
                {/* <p>Number : +1 8482528532</p> */}
            </div>
        </div>
    )
}

export default CommunityGuidelines
import React from 'react'
import { appStore, googlePlayStore, logo } from '../../assets/svg'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className='fixed top-0 left-0 z-20 py-8 lg:px-24 px-5 flex justify-between items-center w-full'>
        <div>
            <span className='*:lg:max-w-48 *:max-w-32 *:w-full inline-block'>{logo}</span>
        </div>
        <div className='flex items-center gap-3'>
            <Link to='/' className='lg:size-[60px] size-[50px] *:w-full *:md:max-w-6 *:max-w-5 bg-black border-2 rounded-lg flex items-center justify-center'>
                {appStore}
            </Link>
            <Link to='/' className='lg:size-[60px] size-[50px] *:w-full *:md:max-w-6 *:max-w-5 bg-black border-2 rounded-lg flex items-center justify-center'>
                {googlePlayStore}
            </Link>
        </div>
    </div>
  )
}

export default Header
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

const PrivacyPolicy = () => {
    return (
        <div className='max-w-3xl px-3 py-6 mx-auto text-xl'>
            <div className='max-w-24 mb-4 mx-auto'>
                <Link to='/'>
                    <img src={logo} alt="dipss" />
                </Link>
            </div>
            <h2 className='text-3xl font-bold text-center'>Privacy Policy</h2>
            <p className='text-center'>Effective Date: [10-05-2024]</p>
            <p className='mt-5'>Timbadiya Sanjay Parshottambhai is committed to protecting our customer's privacy. Please take the time to read this Privacy Policy which explains what information we collect about you, how we use it, and your rights. Timbadiya Sanjay Parshottambhai (“we” or “us”) is the data controller of the personal data collected via or in connection with <Link to='https://dipss.io/' className='underline'>https://dipss.io/</Link> and our mobile application (collectively referred to as the “Site”).</p>


            <div className='mt-5 font-semibold'>
                WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU?
            </div>
            <p>
                We collect personal data from you when you provide it to us directly and through your use of the Site. This information may include:
            </p>
            <ul className='list-disc pl-8'>
                <li>When using our Site, you may provide personal details such as your name, contact information, and profile data. This may include specific information like your date of birth, gender, sexual orientation, relationship status, dating preferences, religion, educational background, and language preferences;
                </li>
                <li>
                    Additional personal details may include your height, drinking and smoking habits, perspectives on children and pets, personality traits, and values. You may also provide a personal bio and list of interests to help other users learn more about you and foster deeper connections;
                </li>
                <li>
                    Records of your interactions with us (e.g. if you contact our customer service team, interact with us on social media);
                </li>
                <li>
                    Information you provide us when you enter a competition or participate in a survey;
                </li>
                <li>
                    Information collected automatically, using cookies and other tracking technologies (e.g. which pages you viewed and whether you clicked on a link in one of our email updates). We may also collect information about the device you use to access our Site; and
                </li>
                <li>
                    Other information necessary to provide the Site, for example we may access your location if you give us your consent.
                </li>
            </ul>

            <div className='mt-5 font-semibold'>
                WHAT DO WE USE THIS PERSONAL DATA FOR?
            </div>
            <p>Depending on how you use our Site, your interactions with us, and the permissions you give us, we may use your personal data for the following purposes:
            </p>
            <ul className='list-disc pl-8'>
                <li>To maintain your online account and verify your identity, including your date of birth for age verification and access restrictions.
                </li>
                <li>To manage and respond to any queries or complaints submitted to our customer service team.
                </li>
                <li>To personalize the Site for you and show content we think you will be most interested in, based on your gender, sexual orientation, relationship status, dating preferences, religion, educational background, and language preferences, as well as your account information, purchase history, and browsing activity.
                </li>
                <li>
                    To improve and maintain the Site and monitor its usage, including information related to your lifestyle preferences, such as your height, drinking and smoking habits, perspectives on children and pets, and personality traits.
                </li>
                <li>
                    To carry out market research, such as contacting you for feedback about our products.
                </li>
                <li>
                    To send you marketing messages and display targeted advertising, where we have your consent or are otherwise permitted to do so.
                </li>
                <li>
                    For security purposes, to investigate fraud, and to protect ourselves and third parties.
                </li>
                <li>
                    To comply with legal and regulatory obligations.
                </li>
            </ul>

            <div className='mt-5 font-semibold'>
                Legal Basis for Processing Your Personal Data
            </div>
            <p>We rely on the following legal basis under data protection laws to process your personal data:
            </p>
            <ul className='list-disc pl-8'>
                <li>Processing is necessary to perform a contract with you or to take steps prior to entering into a contract with you (e.g., where you make a purchase, we use your data to process the payment and fulfil your order).
                </li>
                <li>
                    Processing is based on your consent (e.g., when you contact us with a query, add optional information to your profile, or consent to receive marketing).
                </li>
                <li>
                    We use information about you, such as your preferences and interests, to tailor your experience of the Site, making it more relevant and engaging based on the products and offers you view.
                </li>
            </ul>

            <div className='mt-5 font-semibold'>
                MARKETING
            </div>
            <div className='space-y-4'>
                <p>Depending upon your marketing preferences, we may use your personal data to send you marketing messages by email, phone or post. Some of these messages may be tailored to you, based on your previous browsing or purchase activity, and other information we hold about you.
                </p>
                <p>If you no longer want to receive marketing communications from us (or would like to opt back in!), you can change your preferences at any time by contacting us (details below), clicking on the ‘unsubscribe’ link in any email, or updating your settings in your account. If you unsubscribe from marketing, please note we may still contact you with service messages from time to time (e.g. order and delivery confirmations, and information about your legal rights).
                </p>
                <p>
                    You may also see ads for our Site on third party websites, including on social media. These ads may be tailored to you using cookies (which track your web activity, so enable us to serve ads to customers who have visited our Site). Where you see an ad on social media, this may because we have engaged the social network to show ads to our customers, or users who match the demographic profile of our customers. In some cases, this may involve sharing your email address with the social network. If you no longer want to see tailored ads you can change your cookie and privacy settings on your browser and these third-party websites.
                </p>
            </div>

            <div className='mt-5 font-semibold'>
                WHO DO WE SHARE THIS PERSONAL DATA WITH?
            </div>
            <p>We share customer’s personal data with third parties in the following circumstances:
            </p>
            <ul className='list-disc pl-8'>
                <li>With other companies as necessary to operate the Site.
                </li>
                <li>
                    With our suppliers and service providers working for us, e.g. payment processors.
                </li>
                <li>
                    With our professional and legal advisors.
                </li>
                <li>With third parties engaged in fraud prevention and detection.
                </li>
                <li>
                    With law enforcement or other governmental authorities, e.g. to report a fraud or in response to a lawful request.
                </li>
            </ul>
            <p className='mt-4'>If we sell any business assets, the personal data of our customers may be disclosed to a potential buyer. In this event, we will make reasonable attempts to ensure the buyer will be bound by the terms of this Privacy Policy. Otherwise, where we have your consent or are otherwise legally permitted to do so.
            </p>

            <div className='mt-5 font-semibold'>
                STORAGE AND RETENTION
            </div>
            <p>We will keep your personal data for as long as we need it for the purposes set out above, and so this period will vary depending on your interactions with us. For example, where you have made a purchase with us, we will keep a record of your purchase for the period necessary for invoicing, tax and warranty purposes. We may also keep a record of correspondence with you (for example if you have made a complaint about a product) for as long as is necessary to protect us from a legal claim. Where we no longer have a need to keep your information, we will delete it. Please note that where you unsubscribe from our marketing communications, we will keep a record of your email address to ensure we do not send you marketing emails in future.
            </p>

            <div className='mt-5 font-semibold'>
                DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF GOOGLE ANALYTICS (WITH ANONYMISATION FUNCTION)
            </div>
            <div className='space-y-4'>
                <p>On this Platform, the controller has integrated the component of Google Analytics (with the anonymizer function). Google Analytics is a web analytics service. Web analytics is the collection, gathering, and analysis of data about the behavior of visitors to websites. A web analysis service collects, inter alia, data about the website from which a person has come (the so-called referrer), which sub-pages were visited, or how often and for what duration a sub-page was viewed. Web analytics are mainly used for the optimization of a website and in order to carry out a cost-benefit analysis of Internet advertising.
                </p>
                <p>The operator of the Google Analytics component is Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, United States.
                </p>
                <p>For the web analytics through Google Analytics, the controller uses the application “_gat. _anonymizeIp”. By means of this application the IP address of the Internet connection of the data subject is abridged by Google and anonymized when accessing our Platform from a Member State of the European Union or another Contracting State to the Agreement on the European Economic Area.
                </p>
                <p>The purpose of the Google Analytics component is to analyses the traffic on our Platform. Google uses the collected data and information, among other things, to evaluate the use of our Platform and to provide online reports, which show the activities on our Platform, and to provide other services concerning the use of our Platform for us.
                </p>
                <p>Google Analytics places a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, Google is enabled to analyses the use of our Platform. With each call-up to one of the individual pages of this Platform, which is operated by the controller and into which a Google Analytics component was integrated, the Internet browser on the information technology system of the data subject will automatically submit data through the Google Analytics component for the purpose of online advertising and the settlement of commissions to Google. During the course of this technical procedure, the enterprise Google gains knowledge of personal information, such as the IP address of the data subject, which serves Google, inter alia, to understand the origin of visitors and clicks, and subsequently create commission settlements.
                </p>
                <p>The cookie is used to store personal information, such as the access time, the location from which the access was made, and the frequency of visits of our Platform by the data subject. With each visit to our Platform, such personal data, including the IP address of the Internet access used by the data subject, will be transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass these personal data collected through the technical procedure to third parties.
                </p>
                <p>The data subject may, as stated above, prevent the setting of cookies through our Platform at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, cookies already in use by Google Analytics may be deleted at any time via a web browser or other software programs.
                </p>
                <p>In addition, the data subject has the possibility of objecting to a collection of data that are generated by Google Analytics, which is related to the use of this Platform, as well as the processing of this data by Google and the chance to preclude any such. For this purpose, the data subject must download a browser add-on under the link <Link to='https://tools.google.com/dlpage/gaoptout' className='underline' target='_blank'>https://tools.google.com/dlpage/gaoptout</Link> and install it. This browser add-on tells Google Analytics through a JavaScript that any data and information about the visits of Platform may not be transmitted to Google Analytics. The installation of the browser add-ons is considered an objection by Google. If the information technology system of the data subject is later deleted, formatted, or newly installed, then the data subject must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was uninstalled by the data subject or any other person who is attributable to their sphere of competence or is disabled, it is possible to execute the reinstallation or reactivation of the browser add-ons.
                </p>
                <p>Further information and the applicable data protection provisions of Google may be retrieved under <Link to='https://www.google.com/intl/en/policies/privacy/' className='underline' target='_blank'>https://www.google.com/intl/en/policies/privacy/</Link> and under <Link to='http://www.google.com/analytics/terms/us.html' className='underline' target='_blank'>http://www.google.com/analytics/terms/us.html</Link> . Google Analytics is further explained under the following Link <Link to='https://www.google.com/analytics/' className='underline' target='_blank'>https://www.google.com/analytics/</Link>.
                </p>
            </div>

            <div className='mt-5 font-semibold'>
                DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF GOOGLE FIREBASE
            </div>
            <div className='space-y-4'>
                <p>In our commitment to safeguarding your data and ensuring your privacy, we employ Google Firebase within our Site. Firebase, a cloud-based solution by Google, is tailored to streamline the development and management of mobile and web applications. Understanding the data protection provisions and security measures inherent in Firebase is crucial for users.
                </p>
                <p>Security measures integrated into Google Firebase span several critical areas:
                </p>
                <p>Data Center and Network Security encompasses the use of geographically distributed data centres, ensuring redundancy and high availability. The implementation of redundant systems and backup power solutions fortifies service continuity. Google upholds a stringent process for code review and system security to maintain Firebase's integrity. Comprehensive business continuity and disaster recovery plans mitigate service disruptions during unforeseen events.
                </p>
                <p>Access and Site Controls form a pivotal layer of security. Firebase data centres adhere to stringent security measures, closely monitoring and controlling access through defined procedures. Security devices and surveillance systems are employed to bolster site security, alongside strict access controls, privilege management, and authentication protocols to safeguard data.
                </p>
                <p>Data, the core of privacy concerns, is rigorously managed. Firebase securely stores data isolates it from unauthorized access, and maintains comprehensive logs to track activities. Their decommissioned disk erase policy ensures that data leakage is prevented when disks are no longer in use.
                </p>
                <p>
                    Personnel Security is a foundational element. Google enforces personnel conduct guidelines to ensure ethical and professional behaviour. Background checks and confidentiality agreements are mandatory for employees to protect sensitive data. Providing security training to employees is a priority to ensure adherence to data protection best practices, alongside role-specific security requirements.
                </p>
                <p>
                    In alignment with our commitment to data security and privacy, we collaborate closely with Firebase to uphold industry standards for data protection. For further insights into Google's privacy practices and Firebase's security measures, visit the following links:
                </p>
                <p>
                    Google's Privacy Policy: <Link to='https://www.google.com/intl/en/policies/privacy/' className='underline' target='_blank'> https://www.google.com/intl/en/policies/privacy/ </Link>
                </p>
                <p>
                    Firebase Security Information: <Link to='https://firebase.google.com/support/privacy' className='underline' target='_blank'>  https://firebase.google.com/support/privacy</Link>
                </p>
                <p>Your trust and data security are of paramount importance to us. If you have any questions or concerns regarding the application and use of Google Firebase on our Site, please feel free to contact us.
                </p>
            </div>


            <div className='mt-5 font-semibold'>
                SECURITY
            </div>
            <div>
                <p>This Site ensures that data is encrypted when leaving the Site. This process involves the converting of information or data into a code to prevent unauthorised access. This Site follows this process and employs secure methods to ensure the protection of all credit and debit card transactions. Encryption methods such as SSL are utilised to protect customer data when in transit to and from this Site over a secure communications channel.
                </p>
                <p>Whilst we do everything within our power to ensure that personal data is protected at all times from our Site, we cannot guarantee the security and integrity of the information that has been transmitted to our Site.
                </p>
            </div>


            <div className='mt-5 font-semibold'>
                CHILDREN
            </div>
            <p>Our Site is not intended for, and should not be used by, children under the age of 18. We do not knowingly collect personal data from children under 18.
            </p>

            <div className='mt-5 font-semibold'>
                COOKIES
            </div>
            <div className='space-y-4'>
                <p>The Site uses cookies. Cookies are text files that are stored in a computer system via an Internet browser.
                </p>
                <p>Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which sites and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the data subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.
                </p>
                <p>Through the use of cookies, we can provide the users of this Site with more user-friendly services that would not be possible without the cookie setting.
                </p>
                <p>By means of a cookie, the information and offers on our Site can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our Site users. The purpose of this recognition is to make it easier for users to utilize our Site. The Site user that uses cookies e.g. does not have to enter access data each time the Site is accessed, because this is taken over by the Site, and the cookie is thus stored on the user’s computer system. Another example is the cookie is used to remember language version visitors selected on a Site by different visitors.
                </p>
                <p>The data subject may, at any time, prevent the setting of cookies through our Site by employing a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our Site may be entirely usable.
                </p>
            </div>

            <div className='mt-5 font-semibold'>
                YOUR RIGHTS
            </div>
            <div className='space-y-4'>
                <p>You have certain rights in respect of your personal data, including the right to access, portability, correct, and request the erasure of your personal data.
                </p>
                <p>You also have the right to object to your personal data being used for certain purposes, including to send you marketing. See ‘Marketing’ above, for more details of how to opt-out of marketing.
                </p>
                <p>We will comply with any requests to exercise your rights in accordance with applicable law. Please be aware, however, that there are a number of limitations to these rights, and there may be circumstances where we are not able to comply with your request. To make any requests regarding your personal data, or if you have any questions or concerns regarding your personal data, you should contact us using the details below. You are also entitled to contact your local supervisory authority for data protection.
                </p>
            </div>


            <div className='mt-5 font-semibold'>
                PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED
            </div>
            <p>The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfilment of the contract or the initiation of a contract.
            </p>

            <div className='mt-5 font-semibold'>
                PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT; REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE TO PROVIDE SUCH DATA
            </div>
            <p>We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded. Before personal data is provided by the data subject, the data subject must contact our Data Protection Officer. Our Data Protection Officer clarifies to the data subject whether the provision of the personal data is required by law, contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
            </p>

            <div className='mt-5 font-semibold'>
                CONTACT US
            </div>
            <div>
                <p>If you have any queries on any aspect of our Privacy Policy, please contact our Data Protection Officer on the details below:
                </p>
                <p>Name : Sanjay Timbadiya </p>
                <p>Email : sanjaypatel121991@gmail.com</p>
                {/* <p>Number : +1 8482528532</p> */}
            </div>

        </div>
    )
}

export default PrivacyPolicy
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='fixed bottom-0 left-0 z-20 py-4 lg:px-24 px-5 flex md:flex-row flex-col-reverse gap-2 justify-between items-center w-full bg-black bg-opacity-40'>
        <div>
            <p className='text-white'>Copyright © 2024 Dipss</p>
        </div>
        <div className='sm:space-x-4 sm:block grid grid-cols-2 gap-10'>
            <Link to='/privacy-policy' className='text-white hover:underline'>Privacy Policy</Link>
            <Link to='/terms-of-use' className='text-white hover:underline'>Terms of Use</Link>
            <Link to='/cookie-policy' className='md:inline hidden text-white hover:underline'>Coockie Policy</Link>
            <Link to='/user-guidelines' className='md:inline hidden text-white hover:underline'>User Guidelines</Link>
        </div>
    </div>
  )
}

export default Footer
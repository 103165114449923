import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

const CookiePolicy = () => {
    return (
        <div className='max-w-3xl px-3 py-6 mx-auto text-xl'>
            <div className='max-w-24 mb-4 mx-auto'>
                <Link to='/'>
                    <img src={logo} alt="dipss" />
                </Link>
            </div>
            <h2 className='text-3xl font-bold text-center'>Cookie Policy</h2>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                Introduction
            </div>
            <div className='space-y-4'>
                <p>This Cookie Policy describes how Timbadiya Sanjay Parshottambhai, (hereinafter referred to as “Dipss”, “we”, “us”, and “our”) uses cookies through our website “<Link to='https://dipss.io/' className='underline' target='_blank'>https://dipss.io/</Link>” website, mobile application, related, linked, or otherwise connected thereto (collectively the “Platform”). For the sake of convenience, the term “Services” shall be included in the term “Platform” wherever used throughout this document unless specified otherwise. We recommend that you also consult our Privacy Policy for additional information on how we collect and use information collected from visitors to the Platform. </p>
                <p>Your continued use of the Platform after that will signify your acceptance of this Cookies Policy. We may modify this Cookies Policy without notifying you, so please check back often for updates.
                </p>
                <p>By using the Platform, you agree that we can use the cookies described in this Cookie Policy. You can stop cookies by changing the settings (more information on how to do this is provided below).
                </p>
            </div>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                WHAT ARE COOKIES?
            </div>
            <p>Cookies are text files containing small amounts of information, which are downloaded to your browsing device (such as a smartphone) when you visit a Platform. Cookies can be recognized by the Platform that downloaded them — or other Platforms that use the same cookies. This helps Platforms know if the browsing device has visited them before.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                WHAT ARE COOKIES USED FOR?
            </div>
            <p>
                Cookies do lots of different jobs, like helping us understand how the Platform is being used, letting you navigate between pages efficiently, remembering your preferences, and generally improving your browsing experience. Cookies can also help ensure marketing you see online is more relevant to you and your interests.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                FIRST- AND THIRD-PARTY COOKIES
            </div>
            <p>
                First-party cookies are cookies that belong to us, while third-party cookies are cookies that another party places on your browsing device through our Platform.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                WHAT TYPES OF COOKIES DOES WE USE?
            </div>
            <div className='space-y-4'>
                <p>
                    The type of cookies used on most Platforms can generally be put into the following categories: Strictly Necessary, Session, Secure, Performance, Functionality, Flash, Tailored Content, Targeting, Discovered, Advertisement, and Analytics. To provide you with our Platform, We uses the following cookies:
                </p>
                <p>
                    <span className='font-semibold'> SESSION COOKIES: </span> Session cookies, also known as 'temporary cookies', help websites recognise users and the information provided when they navigate through a website. Session cookies only retain information about a user's activities for as long as they are on the website. Once the web browser is closed, the cookies are deleted. These are commonly used on shopping websites or e-commerce websites.
                </p>
            </div>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                HOW LONG WILL COOKIES STAY ON MY BROWSING DEVICE?
            </div>
            <p>
                The length of time a cookie will stay on your browsing device depends on whether it is a “persistent” or “session” cookie. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your browsing device until they expire or are deleted.
            </p>


            <div className='mt-5 font-semibold text-2xl uppercase'>
                HOW TO CONTROL AND DELETE COOKIES THROUGH YOUR Browsing device
            </div>
            <p>
                The browsing device you are using to view the Platform can enable, disable, or delete cookies. To do this, follow the instructions provided on the Platform (usually located within the “Help,” “Tools,” or “Edit” facility). Please note that if you set your device to disable cookies, you may not be able to access certain parts of the Platform. Other parts of the Platform may also not work properly. Some cookies cannot and should not be disabled as they may be essential for correctly displaying the Platform and providing you with our services.
            </p>

            <div className='mt-5 font-semibold text-2xl uppercase'>
                CONTACTING US
            </div>
            <div>
                <p>If you have any queries on any aspect of our Cookie Policy, please contact us through our Platform or the address given below:</p>
                <p>Name : Sanjay Timbadiya </p>
                <p>Email : sanjaypatel121991@gmail.com</p>
                {/* <p>Number : +1 8482528532</p> */}
            </div>

        </div>
    )
}

export default CookiePolicy